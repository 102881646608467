/* src/components/Common/ErrorPage/ErrorPage.css */
.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Центрируем по вертикали */
    align-items: center;     /* Центрируем по горизонтали */
    text-align: center;
    padding: 20px;
    box-sizing: border-box; /* Учитываем отступы внутри контейнера */
    flex: 1; /* Заставляем элемент занять всю доступную высоту */
}
  
.error-page img {
    max-width: 200px;
    margin-bottom: 20px;
}
  
.error-page h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}
  
.error-page button {
    margin-top: 15px;
}

