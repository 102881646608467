.russia-subscription-offer {
    max-width: 700px;
    margin: 12px auto;
    padding: 18px 24px;
  
    /* Яркий градиент в тёплых тонах */
    background: linear-gradient(135deg, #a014c6, #6a0985);
    border-radius: 24px;
  
    /* Лёгкая тень для выделения блока */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
    text-align: center;
  }
  
  /* Заголовок */
  .russia-subscription-offer__title {
    margin: 0 0 0px;
    font-size: 28px;
    font-weight: 700;
    color: #fff;
  
    /* Лёгкая тень на тексте для контраста */
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  }
  
  /* Подзаголовок */
  .russia-subscription-offer__subtitle {
    margin: 0;
    font-size: 14px;
    color: #fff;
    line-height: 1.6;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  