.subscription-item {
  display: flex;
  align-items: center;
}

.subscription-icon {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.subscription-info {
  flex-grow: 1;
}

.manage-sub-bt{
  margin-left: 10px;
}

.active-subscriptions-container {
  margin-bottom: 20px;
}

.detail-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  align-items: center;
}

.detail-label {
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  text-align: left;
}



.detail-value, .detail-value-status {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  align-items: center;
}

.detail-value-status {
  color: #00A15D; /* Green color for active status */
}

.subscription-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;
}

.card-info-label {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin: 10px 0 10px; /* Spacing around the label */
}

.cancel-subscription-button {
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Добавляем стили для маленьких экранов */
@media (max-width: 600px) {
  .subscription-item {
    flex-direction: row; /* Устанавливаем основной порядок на строку */
    display: flex;
    flex-wrap: wrap; /* Добавляем обертку для элементов */
    padding-top: 20px;
  }
  .manage-sub-bt{
    margin-left: 0px;
  }
  /* Чтобы изображение и текст были в одной строке */
  .subscription-info {
    display: flex;
    flex-direction: column;
    margin-right: auto; /* Чтобы информация "прилипла" к левому краю */
  }

  .subscription-item > .button1 {
    margin-top: 20px;
    width: 100%; /* Кнопка на всю ширину во второй строке */
  }
}
