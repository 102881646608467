.alternative-purchasing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.alternative-purchasing-content {
    max-width: 800px;
    text-align: center;
}

.alternative-purchasing-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
}

.alternative-purchasing-text {
    font-size: 16px;
    line-height: 1.5;
    color: #555555;
    margin-bottom: 20px;
    text-align: left;
}
