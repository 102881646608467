.verification-container {
    text-align: center;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .code-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Добавляем, чтобы поля могли переноситься на следующую строку, если экран слишком узкий */
    max-width: 100%;
  }
  
  .code-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 1.5em;
    border: 2px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s;
    box-sizing: border-box;
  }
  
  .code-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin-left: 15px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .back-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .back-button:hover {
    background-color: #dcdcdc;
  }
  
  .back-button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
  
  .resend-link {
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
    margin-left: 8px;
    padding: 0;
    font-family: inherit;
  }
  
  /* Медиазапросы для мобильных устройств */
  @media (max-width: 600px) {
    .verification-container {
      padding: 1em;
    }
  
    .code-input-container {
      gap: 5px;
      margin-top: 15px;
      margin-bottom: 15px;
      flex-wrap: nowrap; /* Поля не переносятся на новую строку */
    }
  
    .code-input {
      width: 45px; /* Увеличим ширину для лучшей видимости */
      height: 45px;
      font-size: 1.2em;
      flex: 1; /* Поля будут адаптироваться по ширине экрана */
      max-width: 45px;
    }
  
    .back-button {
      width: 100%;
      padding: 12px;
    }
  
    .resend-link {
      font-size: 14px;
    }
  }
  