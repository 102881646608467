/* src/components/ProfilePage/UserProfile/UserProfileCard.css */

.user-container {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.icon-container {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.icon {
  position: absolute;
  max-width: 60px;
  max-height: 60px;
}

.icon_front {
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon_back {
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-container {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  gap: 2px;
}

.right-elements {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.right-image {
  height: 30px;
  width: auto;
  margin-right: 10px;
}

.right-text {
  font-size: 20px;
  color: #2b2b2b;
  font-weight: 800;
  font-family: "Inter", sans-serif;
  margin-right: 5px;
}

.premium-badge {
  background-color: #FFBE00;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 20px;
  margin-left: 5px;
  vertical-align: middle;
  font-family: "Inter", sans-serif;
  display: inline-block;
}

.text-title {
  display: inline-flex;
  align-items: center; /* Для того чтобы и текст, и бейдж выровнялись по центру */
}

@media (max-width: 600px) {
  .right-text {
    font-size: 12px;
    margin-right: 5px;
  }

  .right-image {
    margin-right: 5px;
  }

  .right-elements {
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  .icon {
    position: absolute;
    max-width: 50px;
    max-height: 50px;
  }

  .icon-container {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .id-text {
    font-size: 10px;
  }

  .premium-badge {
    font-size: 10px;
    padding: 3px 6px;
    margin-left: 5px;
  }
}
