/* CardInfo.css */

.card-preview {
    position: relative;
    width: 320px;
    height: 200px;
    background: linear-gradient(135deg, #764ba2 0%, #667eea 100%);
    border-radius: 15px;
    color: #fff;
    padding: 20px 30px; /* Устанавливаем одинаковый отступ слева и справа */
    box-sizing: border-box;
    margin-bottom: 20px;
}

.card-type {
    position: absolute;
    top: 10px;
    left: 30px; /* Отступ слева такой же, как у контейнера */
    display: flex;
    align-items: center;
    height: 60px;
}

.card-type span {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    line-height: 1; /* Устанавливаем line-height для выравнивания */
    vertical-align: middle;
    line-height: 60px;
}

.card-type-icon {
    max-width: 60px;
    max-height: 60px;
}

.card-number {
    font-size: 24px;
    letter-spacing: 2px;
    margin-top: 60px; /* Добавляем верхний отступ для создания пространства */
    margin-bottom: 20px;
}

.card-details {
    display: flex;
    justify-content: space-between;
}

.card-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Выравнивание по левому краю */
}

.card-expiry {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Выравнивание по правому краю */
}

.card-details label {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 5px;
    display: block;
}

.card-details span {
    font-size: 16px;
}
