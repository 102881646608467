/* src/components/Modal/Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow-y: auto;
}

.modal-content {
  background-color: #fff;
  padding: 15px;
  border-radius: 20px;
  max-width: 95%;
  width: 500px;
  text-align: center;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content h2 {
  margin-top: 10px;

}

.button-close {
  margin-top: 15px;    
  margin-bottom: 10px;
}

/* Обновленные стили для круглой кнопки с крестиком */
.modal-close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 45px; /* Размер кнопки */
  height: 45px;
  border: none;
  background-color: #f2f2f2; /* Фоновый цвет кружка */
  cursor: pointer;
  border-radius: 50%; /* Делаем кнопку круглой */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}


.modal-close-image {
  width: 12px; /* Размер изображения крестика */
  height: 12px;
}

.modal-close-icon:hover {
  background-color: #e0e0e0; /* Цвет фона при наведении */
  color: #000; /* Цвет крестика при наведении */
}

.modal-title {
  margin: 0;
  display: inline-block;
  max-width: calc(100% - 110px); /* Adjust based on the close button size */
  word-wrap: break-word; /* Allows the title to wrap to the next line if it's too long */
  margin-bottom: 15px;
}

.modal-header {
  position: relative;
  text-align: center;
  padding: 5px;
}
