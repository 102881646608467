.footer {
  display: flex;
  flex-direction: column;
}

.footer-line {
  padding: 10px 30px 10px 30px;
  background-color: #fff;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
}

.footer-cpoyright-line {
  padding-bottom: 10px;
  background-color: #ffffff;
}

.language-select {
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  cursor: pointer;
  color: #000;
  text-align: center;
  margin: auto 0;
}

.social-media img{
  max-width: 30px;
  max-height: 30px;
}

.social-media{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.language-select:hover {
  background-color: #fff;
  border-color: #000;
}

.footer-info {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
}


.copiright {
  justify-content: center;
}

.footer-info p {
  font-size: 12px;
  line-height: 1;
  color: #718EBF;
}

.footer-links  {
  display: flex;
  gap: 20px;
  justify-content: center;

}

.footer-links a {
  word-break: break-all;
}

.footer a {
  text-align: center;
  color: #718EBF;
  text-decoration: none;
  font-size: 14px;
  text-wrap:wrap;
}

.footer-links a:hover {
  text-decoration: underline;
}


@media (max-width: 600px) {
  .footer-info {
    justify-content: center;
    gap: 20px;
  }
  
}