*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Inter";
  font-weight:400;
  margin: 0;
  padding: 0;
  background-color: #f5f5f7;
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  
  /* Remove any padding or margin if necessary */
}

.container {
  flex: 1 1 auto;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2em;
  text-align: left;
  box-sizing: border-box;
}

/* .container {
  flex: 0 0 auto; 
  width: 100%;   
  max-width: 800px;
  margin: 0 auto;
  padding: 2em;
  text-align: left;
} */



.input1 {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight:400;
}

/* 00A15D */

.button1 {
  background-color: #00A15D; 
  color: #fff;
  border: none;
  border-radius: 80px;
  width: 300px;
  padding: 0.75em 1.5em;
  font-size: 1.15em;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  font-family: "Inter", sans-serif;
  font-weight:500;
}

.button1:hover {
  background-color: #008f4a;
}

.button2 {
  color: #d9534f;
  background: none; 
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s;
  font-family: "Inter", sans-serif;
  font-weight:400;
}

.button2:hover {
  color: #782f2c;
}

.button3 {
  background-color: #d9534f; 
  color: #fff;
  border: none;
  border-radius: 80px;
  width: 300px;
  padding: 0.75em 1.5em;
  font-size: 1.15em;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  font-family: "Inter", sans-serif;
  font-weight:500;
}

.button3:hover {
  background-color: rgb(109, 42, 40);
}


p {
  font-family: "Inter", sans-serif;
  font-weight:400;
}

h2 {
  font-family: "Inter", sans-serif;
  font-weight:600;
}


.profile-section {
  margin-bottom: 3em;
}

.profile-section-white-box {
  /* background-color: #fff; */
  background: linear-gradient(135deg, #ffffff, #ffffff);
  padding: 15px 15px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.03);
  min-height: 40px;
}

.profile-section-title {
  color: #000;
  font-family: "Inter", sans-serif;
  font-weight:600;
  font-size: 28px;
}

.text-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.text-description {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #718EBF;
}

.text-regular {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  color: #000000;
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex: 1;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2em;
  text-align: left;
}

/* Медиазапросы для мобильных устройств */
@media (max-width: 600px) {
  .container {
    padding: 1em; /* Уменьшаем отступы на мобильных устройствах */
  }

  .profile-section-title {
    font-size: 24px; /* Уменьшаем размер заголовков на мобильных устройствах */
  }

  .text-title {
    font-size: 15px;
  }

  .text-description {
    font-size: 13px;
  }

  .button1 {
    width: 100%; /* Кнопка будет занимать всю ширину на мобильных устройствах */
  }
}
