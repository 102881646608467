.promo-code-container {
    text-align: center;
    display: flex;
  }

.promo-code-button {
    flex: 1;
    margin-left: 20px;
}

.promo-code-input {
    flex: 3;
}
  
@media (max-width: 600px) {
    .promo-code-container {
        flex-direction: column; 
        gap: 20px;
    }

    .promo-code-button {
        margin-left: 0px;
    }

}