/* src/components/ProfilePage/FamilySubscriptionManager/FamilySubscriptionManager.css */
.family-info {
}

.family-members-list {
  list-style: none;
  padding-left: 0;
}

.family-members-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; /* Отступ между элементами */
  padding-bottom: 10px; /* Вертикальные отступы между элементами */
  flex-wrap: wrap; /* Добавляем возможность переноса для адаптивности */
  row-gap: 8px;
}

.user-card {
  flex: 3; /* Занимает 3/4 ширины */
}

.remove-button {
  flex: 1; /* Занимает 1/4 ширины */
  background-color: #d9534f;
  color: #fff;
}

.remove-button:hover {
  background-color: #c9302c;
}

.leave-button {
  background-color: #d9534f;
  color: #fff;
  margin-top: 20px;
}

.leave-button:hover {
  background-color: #c9302c;
}

.add-member-section {
  display: flex;
  justify-content: center; /* Центрирование кнопки */
  align-items: center;
}

.no-family-subscription {
  background-color: #fff;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
}

.text-regular {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.input-add-member{
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.no-subscribtion-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.no-subscribtion-panel-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.alertText{
  color: #d9534f;
}

/* Для мобильных экранов (например, ширина до 600px) */
@media (max-width: 600px) {
  .family-members-list li {
    flex-direction: column; /* Переносим элементы в колонку */
    align-items: flex-start; /* Выравниваем элементы по началу */
    gap: 8px; /* Уменьшаем расстояние между элементами */
  }

  .user-card {
    flex: none; /* Сбрасываем flex-grow для адаптивности */
    width: 100%; /* Карточка пользователя занимает 100% ширины */
  }

  .remove-button {
    flex: none; /* Сбрасываем flex-grow для адаптивности */
    width: 100%; /* Кнопка удаления занимает 100% ширины */
    text-align: center; /* Центрируем текст на кнопке */
  }
}