/* src/components/TestLoginButton/TestLoginButton.css */
.test-login-button {
    margin: 0.5em;
    padding: 0.75em 1.5em;
    font-size: 1em;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .test-login-button:hover {
    background-color: #0056b3;
  }
  