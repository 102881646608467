/* src/components/AuthMethods/AuthMethods.css */

.auth-methods-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-family: 'Inter', sans-serif;
  }
  
  .logo {
    max-width: 50px;
  }
  
  .title {
    margin-bottom: 25px;
  }
  
  .auth-button {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    min-height: 50px;
  }

    
  .input {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .email-button {
    width: 100%;
    box-sizing: border-box;
    background-color: #8808ab;
  }

  .email-button:hover {
    background-color: #6e088a;
  }
  
  .google-button {
    background-color: #fff;
    color: #000;
  }

  .google-button:hover {
    background-color: #ebebeb;
  }
  
  .google-button img {
    margin-right: 10px;
    width: 20px;
  }

  .facebook-button{
    background-color: #225dd4;
  }

  .facebook-button:hover{
    background-color: #1a418e;
  }
  
  
  .apple-button {
    background-color: #000;
    color: #fff;
  }

  .apple-button:hover {
    background-color: #515151;
  }
  
  .apple-button img {
    margin-right: 10px;
    width: 20px;
  }
  
  .separator {
    margin: 25px 0;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }
  
  .auth-footer{
    font-size: 14px;
    color: #6a6a6a;
    margin-top: 20px;
  }

  .auth-footer a{
    color: #000;
    font-weight: 600;
  }