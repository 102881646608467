.subscription-container {
  text-align: center;
  background: linear-gradient(135deg, #1d1247, #3a5a6f);
  /* background: linear-gradient(135deg, #1d1247, #2F4B5E); */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.subtitle {
  font-size: 18px;
  color: #fff;
}

.info {
  font-size: 14px;
  color: #fff;
  margin: 15px;
  font-weight: 300;
}

.subtitle strong{
  font-size: 20px;
}

.subscription-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subscription-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%; 
  gap: 15px;
  padding-left: 15px;
  padding-top: 20px;
  padding-right: 15px;

}

.subscription-option {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  position: relative; /* Fix position relative */
}

.subscription-option.selected {
  border: 2px solid #00A15D;
  background-color: #e6f4ea;
}

.subscription-option input[type="radio"] {
  display: none; /* Скрыть стандартный вид радиокнопки */
}

.subscription-option .custom-radio {
  width: 24px;
  height: 24px;
  border-radius: 50%; /* Сделать радиокнопку круглой */
  background-color: #fff;
  border: 2px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  position: relative;
}

.subscription-option.selected .custom-radio {
  background-color: #00A15D;
  border-color: #00A15D;
}

.radio-checkmark {
  width: 10px;
  height: 10px;
  border-radius: 50%; /* Круглая белая точка внутри радиокнопки */
  background-color: #fff;
  display: none;
}

.subscription-option.selected .radio-checkmark {
  display: block;
}

.subscription-option.selected span {
  color: #000;
}


.subscription-option label {
  display: flex;
  flex-direction: column; 
  text-align: left;
  cursor: pointer;
}


.price {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

.description {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #98b1dc;
}

.subscription-image {
  width: 50%; /* Изображение занимает другую половину контейнера */
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription-image img {
  max-width: 100%;
  max-height: 100%;
}

.footer-text {
  margin-top: 15px;
  font-size: 12px;
  color: #b8c8e4;
}

.continue-button {
  margin-top: 20px;
  background-color: #00A15D;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.continue-button:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0);
}

/* .continue-button {
  min-width: 300px;
  margin-top: 20px;
  background-color: #FFBE00;
  color: #000;
} */

@media (max-width: 600px) {
  .subscription-content {
      flex-direction: column; 
      display: flex;
      justify-content: flex-end;
      gap: 10px;
  }

  .subscription-options {
    width: 100%;
  }
  .subscription-image {
    width: 60%;
    justify-content: center;
    align-items: center;
    order: -1;
  }

  .continue-button {
    margin-top: 10px;
  }
}