/* src/components/LoginMethods/LoginMethods.css */

.login-methods-container {
    text-align: center;
}
  
  .login-methods-list {
    margin-bottom: 20px;
  }
  
  .login-method {
    display: flex;
    align-items: center;
  }
  
  .login-method-logo {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  
  .login-method-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  
  .add-login-method-button:hover {
    background-color: #008f4a;
  }

  @media (max-width: 600px) {
    .mobile-button-padding{
      padding: 0px 15px;
    }
  }
  