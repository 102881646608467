/* Грид-контейнер, где карточки сами распределяются по ширине */
.shop-grid {
  display: grid;
  /* 
    auto-fit означает "столько колонок, сколько поместится", 
    minmax(230px, 1fr) задаёт минимальную ширину в 230px и растягивает до 1fr 
  */
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 20px;
}

/* Карточка: используем flex, чтобы расположить блоки top - middle - bottom 
   с равномерным распределением по вертикали */
.shop-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch; 
  text-align: center;
  /* 
    Убираем фиксированную ширину и высоту!
    Это позволит карточкам автоматически подстраиваться под сетку.
  */
}

/* Верхняя часть (картинка) */
.shop-card-top {
  /* Пусто, flex сам распределит */
}

/* Картинка делаем адаптивной */
.shop-card-image {
  display: block;
  /* Не превышаем 150px по высоте */
  max-height: 150px;
  /* Не выходим за пределы контейнера по ширине */
  max-width: 100%;
  /* Для корректного масштабирования по аспекту */
  height: auto;
  width: auto;
  margin: 0 auto;
  margin-bottom: 10px;
}

/* Средняя часть (количество алмазов и скидка) */
.shop-card-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Нижняя часть (кнопка) - «прилипает» к низу, т.к. justify-content: space-between в родителе */
.shop-card-bottom {
  margin-top: 10px;
}

.shop-card-title {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin: 0;
}

/* Иконка алмазов */
.diamond-icon {
  width: 30px;
  height: auto;
  vertical-align: middle;
}

/* Кнопка */
.diamond-buy-bt {
  width: 100%;
  max-width: none; /* если вдруг max-width: 300px; стоит */
  box-sizing: border-box;
}

/* Бейдж со скидкой */
.discount-badge {
  background-color: #ff5b5b;
  color: #fff;
  font-family: "Inter", sans-serif;
  margin-top: 4px;
  font-weight: 600;
  border-radius: 12px;
  padding: 2px 10px;
}

/* Пример адаптива, если хотите подстраивать какие-то размеры под мобильный */
@media (max-width: 600px) {
  .diamond-buy-bt {
    font-size: medium;
    width: 100%;
  }

  .shop-grid {
    gap: 15px;
  }

  .shop-card-image {
    height: 120px;
  }

}
