.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  }
  
  .navbar-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .navbar-logo {
    height: 40px;
    margin-right: 10px;
  }
  
  .navbar-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #000;
  }
    
  .logout-button {
    color: #d9534f;
  }